import React, { useState } from 'react';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import scrollTo from 'gatsby-plugin-smoothscroll';

import { HERO_SECTION_ID } from '../../constants';

export default function ScrollToTop() {
    const [isOnTop, setIsOnTop] = useState(true);

    useScrollPosition(({ prevPos, currPos }) => {
        if (currPos.y < 0) {
            setIsOnTop(false);
        } else {
            setIsOnTop(true);
        }
    });

    return (
        !isOnTop && <button onClick={() => scrollTo(`#${HERO_SECTION_ID}`)} className="transition duration-500 ease-in-out fixed z-10 right-10 bottom-10 rounded-full p-3 w-16 h-16
        bg-blue-500 hover:bg-blue-700 shadow-xl hover:shadow-2xl shadow-inner outline-none focus:outline-none">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="white">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
            </svg>
        </button>
    );
}