import React from 'react';
import { StaticImage } from "gatsby-plugin-image";
import { Step, Paragraph, Tag } from '../shared';

export default function WindowCleaning() {



    return (
        <div>
            <div className="flex sm:flex-row flex-col w-full mb-16">
                <div className="sm:w-1/2 w-full p-2 flex justify-end">
                    <StaticImage src='../../../static/windowcleaningltd.jpg' alt="Window Cleaning Ontario" width={370} height={320} />
                </div>
                <div className="sm:w-1/2 w-full pl-10 pt-2 pr-10 text-justify text-gray-600">
                    <ul className="list-disc">
                        <li>Problem solving for: difficult access, angled skylights,
                        overhangs, mid-air changeovers and all glass facades, canopy and much more!</li>
                        <li>Fully licence for: High rise, low rise, swing stage,
                        bonus's chair and townhouse, elevated machines
                        usage ( boom, scissor lift ) and ladder work.
                        Our employees are constantly trained and certified
to keep up with Ontario window cleaning regulations</li>
                        <li>Fully compliant with health and safety regulations of
                        Ontario, ( R.R.O. 1990, Regulation 859- Window Cleaning,
380/91, 533/92 )</li>
                        <li>Certificate of Insurance and WSIB clearance for
your consideration.</li>
                    </ul>
                </div>
            </div>
            <div className="sm:w-3/4 w-full m-auto">
                <Paragraph>
                    G.C. Window Cleaning Service (a division of A-Team Pro Cleaning Ltd) was founded with one
                    vision; to provide quality driven, consistent service for our clients. We pride ourselves on being the
                    leading window cleaning service for post construction, residential, and low rise commercial/residential
                    properties in the GTA.
            </Paragraph>
                <Step>
                    <Step.Item header="1. Current Situation">
                        Having an endless rotation of window cleaning companies is certainly no fun. So is not having any at all.
                        Depending on the quality of your Janitorial Services, the exterior of your windows may not be cleaned
                        regularly. And, if you do decide to hire a window cleaner, they may not show up, they might leave the
                        job in poor shape, abuse the privilege of working on your facility, interrupt your work flow, and forget
                        that the most important part of this equation is you – the CUSTOMER. Almost worse is having no current
                        contractor on a regular schedule – your building can look dirty for months at a time.
                </Step.Item>
                    <Step.Item header="2. Solution">
                        We will treat your building as if it were our own. G.C. Window Cleaning employees realize that the first
                        impression your clients, employees, and visitors get of your building is its appearance. We will keep that
                        first impression in G.C. Window Cleaning shape. We deliver what we say we will, when we say we will.
                        With so many choices of window cleaners today, wouldn’t it be nice to find one that delivers consistent
                        quality, and unmatched service? That is what we do, every day, for every client. We stay on the cutting
                        edge of the latest developments in the industry, and always work within the guidelines of the OHSA –
                        R.R.O. 1990, Regulation 859 - Window cleaning 380/91, 533/92.
                </Step.Item>
                    <Step.Item header="2.1 Objectives">
                        We will service your building in the most efficient and safety conscious way. Our dedication to quality
                        service means we can manage the needs of your building, the right way – every time.
                        <ul className="list-disc mt-4 ml-4">
                            <li>On Time delivery of quality service</li>
                            <li>Cleaning designated windows and wiping ledges</li>
                            <li>Scraping of all debris off the glass windows.</li>
                            <li>Always on site with All PPE – at all times.</li>
                        </ul>
                    </Step.Item>
                    <Step.Item header="2.2 Approach">
                        We will utilize any and all means necessary to complete your project. Whether it is pole work, elevated
                        machine, pure water technology, or ―nose to glass‖ ladder work, we will get the job done right.
                </Step.Item>
                    <Step.Item header="2.3 Benefits">
                        You will finally be able to stop chasing window cleaners for bids, seeing the results of their poor quality
                        and realize the value of having a company that actually cares about the job they are hired to do.
                        <ul className="list-disc mt-4 ml-4">
                            <li>We are fully insured. $5M liability insurance.</li>
                            <li>One company to deal with, for as long as you’d like</li>
                            <li>The value of truly clean windows leaves a lasting impression</li>
                            <li>Your time is spent on other things – leave the windows to us</li>
                            <li>Your budgets can be set with one year or longer service agreements</li>
                            <li>You will never be unhappy to see G.C.Window cleaning on the job!</li>
                            <li>We use only the best equipment, chemicals, and methods.</li>
                        </ul>
                    </Step.Item>
                    <Step.Item header="3. Implementation Plan">
                        <div className="flex sm:flex-row flex-col w-full mb-16">
                            <div className="sm:w-1/2 w-full pl-10 pt-2 pr-10 text-justify text-gray-600">
                                <ul className="list-disc">
                                    <li>Anchor Reports</li>
                                    <li>Site - specific work plan ( SSWP)</li>
                                    <li>Emergency response plan</li>
                                    <li>Rescue Procedures</li>
                                    <li>Post - Rescue Procedures</li>
                                </ul>
                            </div>
                            <div className="sm:w-1/2 w-full p-2">
                                <StaticImage src='../../../static/implementation_plan.png' alt="Window Cleaning Implementation Plan" height={100} />
                            </div>
                        </div>
                    </Step.Item>
                    <Step.Item header="3.1 Methodology">
                        On all cleaning, a combination of professional detergents and tools will be utilized. Ladders will be used
                        where deemed necessary. On the agreed upon schedule, we will arrive between 8:00 AM and 9:00 AM.
                        We will perform our services until 5:30 PM each day until the job is complete. Completion of full window
                        cleaning for your building should take as per schedule ( weather dependent) .
                        <ul className="list-disc pt-4 pl-4">
                            <li>Apply chemical cleaning solution mix with water and remove most of the dirt build up.</li>
                            <li>Remove all caulking in windows and silicon, scrap and wash.</li>
                            <li>Apply water mixed with a mild detergent and remove any dirt left over</li>
                        </ul>
                    </Step.Item>
                    <Step.Item header="3.2 Window Caulking">
                        <div className="flex sm:flex-row flex-col w-full mb-16">

                            <div className="sm:w-1/4 w-full p-2">
                                <StaticImage src='../../../static/window_caulking_3_2.png' alt="Window Cleaning Implementation Plan" height={100} />
                            </div>
                            <div className="sm:w-3/4 w-full text-justify text-gray-600">
                                Window Caulking is an essential part of the building maintenance. Caulking acts
                                as the insulation between the frame and the exterior of the building. Proper
                                insulation lowers energy costs and decreases the risk of water leaks; preventing
                                water damage to the interior of the property. We use waterproof commercial
                                caulk: it is flexible and fast drying and compliant in all restricted areas and
                                meets ASTM C834 regulations. We service both High-rise and low rise
                                structures.
                            </div>
                        </div>
                    </Step.Item>
                    <Step.Item header="3.3 Chandelier Cleaning">
                        Crystal Chandeliers add elegance and character to any home or business but cleaning it from dust and
                        debris can be a tedious and challenging job. Let us help maintain them for you by returning your
                        chandelier to its original shine. We exercise different detailing and restoration techniques depending on
                        the materials and condition of your chandeliers.
                </Step.Item>
                    <Step.Item header="3.4 Festive Lights">
                        <div className="flex sm:flex-row flex-col w-full mb-16">

                            <div className="sm:w-1/4 w-full p-2">
                                <StaticImage src='../../../static/festive_lights.png' alt="Festive lights" height={100} />
                            </div>
                            <div className="sm:w-3/4 w-full text-justify text-gray-600">
                                Festive lights are perfect for attracting onlookers and enhancing the
                                festive atmosphere of your property. We will install and take down,
                                maintain, and store your high grade commercial lights.
                            </div>
                        </div>
                    </Step.Item>
                    <Step.Item header="3.5 Exterior Vent Cleaning">
                        <div className="flex sm:flex-row flex-col w-full mb-16">
                            <div className="sm:w-3/4 w-full text-justify text-gray-600">
                                Exterior vent cleaning is a vital part of regular building maintenance. This
                                cleaning is required to ensure all dust is eliminated. It is strongly
                                recommended for vents to be cleaned annually to limit the amount of
                                debris build-up. Clean vents increase energy efficiency, and ensure a
                                clean and healthy breathing environment. Neglecting to perform these
                                regular cleans can result in expensive repairs and possible health risks.
                            </div>
                            <div className="sm:w-1/4 w-full p-2">
                                <StaticImage src='../../../static/vent_cleaning.png' alt="Exterior Vent Cleaning" height={100} />
                            </div>
                        </div>
                    </Step.Item>
                    <Step.Item header="3.6 Resources">
                        G.C. Window cleaning Ltd will provide any and all necessary equipment to complete the job. We have
                        the knowledge, equipment, and desire to help you bring your buildings looking their best. We use only
                        the best equipment, chemicals, and methods. Our reputation for safety is above reproach. In fact,
                        Customer Service is our #1 Goal, but Safety is our #1 Priority. We believe that a feeling of safety given to
                        our customers is invaluable. This is our commitment to you—We will work as safely as possible while on
                        your property. But, just for your peace of mind, we will supply you with a Certificate of General Liability
                        Insurance, and WSIB Clearance.
                </Step.Item>
                    <Step.LastItem header="3.7 Qualifications">
                        We have a proven track record of keeping our clients satisfied. We will never be the lowest priced
                        window cleaning firm, but we will always exceed your expectations. We service a number of facilities
                        similar to yours, and shall include a List of References for your review and peace of mind, if requested.
                        We challenge each employee to be a positive role model for friends, family, and customers, especially
                        when we are not on the clock. This ensures that our employees are capable of being the most
                        professional and trustworthy window cleaners in the area while on duty. Fully licensed with working at
                        heights, window cleaning course as well as health and safety. Over 45-years of combined experience
                        staff. Our Client portfolio consists of management properties such as:
                        <br /><br />
                        <Tag>CROSSBRIDGE</Tag>
                        <Tag>DEL CONDO</Tag>
                        <Tag>CONCORD DEVELOPMENTS</Tag>
                        <Tag>FIRST SERVICE</Tag>
                        <Tag>GPM</Tag>
                        <Tag>MAPLE RIDGE</Tag>
                        <Tag>MALVERN</Tag>
                        <Tag>ICON PROPERTY MANAGEMENT</Tag>
                        <Tag>DASH</Tag>
                        <Tag>ROYAL GRANDE</Tag>
                        <Tag>ANDREJS MANAGEMENT</Tag>
                        <Tag>DUKA MANAGEMENT</Tag>
                        <Tag>DONMEG DEVELOPMENTS</Tag>
                    </Step.LastItem>
                </Step>
            </div>
        </div>
    );
}