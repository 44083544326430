import React from 'react';

import Step from '../shared/Step';
import { Accordion, AccordionItem } from '../shared/Accordion';
import Paragraph from '../shared/Paragraph';

export default function AboutUs() {



    return (
        <div>
            <Paragraph noTopMargin>A-Team Pro Cleaning Ltd. is a commercial and residential/condominium cleaning expert with a vast
            majority of accounts completed on a daily/nightly basis. Our business model is a bit unique in comparison
            to many of our competitors in that we conduct random quality controls to our staff as well as, an open
            communication with the clients at all times, and we feel that this gives us a distinct advantage. This strives
us towards making your standards our standards.</Paragraph>

            <Paragraph>A-Team Pro Cleaning Ltd was founded with two primary principles in mind: to provide customer service
            levels that exceeded anything seen in the janitorial industry and to treat our employees as partners and
            help them improve their lives and environments. With this in mind, our value proposition is centered
            around delivering consistent high quality and professional cleaning services through a vest group of
            employees. We feel that it is our focus and dedication to excellent customer service, and our ability to
place skilled employees on each account to deliver the customer satisfaction needed.</Paragraph>

            <Paragraph>In today’s fiercely competitive business climate, every aspect in the way an organization presents
            themselves matters, and you can rest assured that we are using the most advanced products and
                equipment available.</Paragraph>

            <Paragraph>Our Customer base is wide and varied. With over 8 years in operation and over hundred of Customers in
                GTA you could say we know a thing or two about providing great janitorial services to our clients!</Paragraph>


            <Paragraph>A-Team Pro Cleaning is always interested in making more customers and references while fulfilling their
            standards of clean and healthy environment. You are always welcomed to give us a call and provide an
                opportunity to show that once we take over; cleaning is not your headache anymore.</Paragraph>


            <Step title="A-Team Facts">
                <Step.Item header="SAFE AND EFFECTIVE SERVICE">
                    All of our cleaning equipment procedures and products are selected to provide service
                    that is both effective and safe for your environment. The level of cleaning we provide not
                    only supplies a safe and attractive workplace but yields a healthier environment for your staff and visitors.
                </Step.Item>
                <Step.Item header="EXTENDING THE LIFETIME OF FLOORS">
                    Maintaining a high level of cleanness also extends the lifetime of your floors
                    and furniture reducing the long-term costs for your company or organization.
                </Step.Item>
                <Step.Item header="HIGH STANDARDS OF CLEANING">
                    At A-Team Pro Cleaning we are passionate about our work. Our well trained uniformed staff
                    undergoes a continuous process of evaluation to maintain high standards of cleaning and maintenance.
                </Step.Item>
                <Step.Item header="UNINTERRUPTED SERVICE">
                    With a full time and part time cleaning staff, we can provide uninterrupted service for
                    your office and commercial/residential areas.
                </Step.Item>
                <Step.Item header="DELIVERY OF SERVICE">
                    A-Team Pro Cleaning LTD maintains an enhanced supervisor-to-cleaner ratio to guarantee the
                    delivery of service suitable to your needs.
                </Step.Item>
                <Step.Item header="TECHNICAL EXPERTISE">
                    We pride ourselves on our technical expertise and are committed to exceeding our clients' expectations.
                </Step.Item>
                <Step.Item header="CLIENT NEEDS">
                    We work together with our clients to understand their needs, systems, and expectations and offer a flexible approach that ensures our cleaning exceed expectations.
                </Step.Item>
                <Step.LastItem header="KNOWLEDGE">
                    Our experts have a wealth of knowledge and a can-do approach which, in turn,
                    inspires, motivates and encourages the rest of our team.
                </Step.LastItem>
            </Step>


            <Accordion>
                <AccordionItem headerTitle="Is your company bonded and insured?">
                    Bonding for unanticipated employee dishonesty should be in place. We suggest a minimum of $10,000.
                    Insurance is of its most importance to protect against any accidental damage that could occur.
                    Again, we would suggest (as we already practice) a minimum of $5,000,000.
                    A client can request the vendor to increase liability insurance and that the client’s name be included on the
                    actual insurance policy

                </AccordionItem>
                <AccordionItem headerTitle="Do you have a quality control program in place and what does it consist of?">
                    Let’s face it. Quality control is probably the single biggest challenge a company has to deal with.
                    Faxable housekeeping reports or any similar printed forms that allow you to communicate concerns
                    by checking off boxes are essential to a quality control program. In this way your time spent is kept
                    to a minimum while providing a hard copy that can be logged and dealt with. Vendor-initiated surveys
                    can be another essential tool. ATPC has detailed reports of the quality controll.

                </AccordionItem>
                <AccordionItem headerTitle="Do you have regular supervisor inspections?">
                    Routine inspections by a knowledgeable supervisor help to scrutinize any shortfalls in quality before you notice them.
                    Many cleaning companies don’t provide this because they cannot afford to have someone on the payroll
                    dedicated to this function. ATPC has this convered on regular basis.

                </AccordionItem>
                <AccordionItem headerTitle="How many years have you been in business?">
                    If possible you should try to choose a vendor that has a minimum of 3 years experience.
                    There are many fly-by-night companies that are here today and gone tomorrow. We are not one of them!!!

                </AccordionItem>
                <AccordionItem headerTitle="Do you have a current clearance certificate from WSIB?">
                    If a worker has an accident on your premises and the cleaning company he or she is working for
                    does not have WSIB coverage, your company could be held liable for any compensation that may be
                    incurred from the injury. Need we say more? We are fully insured.

                </AccordionItem>
                <AccordionItem headerTitle="What kind of communication can I expect from your cleaning company?">
                    Depending on your size you should expect a pre-emptive phone call at least once per month. We can promise you that!

                </AccordionItem>
            </Accordion>

        </div>
    );
}