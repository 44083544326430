import React, { useState } from 'react';


function Accordion({ children }) {
    return (
        <section className="shadow">
            {children}
        </section>
    );
}

function AccordionItem({ children, headerTitle }) {
    const [isOpen, setOpen] = useState(false);

    return (
        <article className={`border-b hover:bg-gray-50 ${isOpen && "bg-gray-50"}`} onClick={() => setOpen(!isOpen)}>
            <div className="border-l-2 bg-grey-lightest border-indigo">
                <header className="flex justify-between items-center p-5 pl-8 pr-8 cursor-pointer select-none">
                    <span className="text-indigo font-thin text-xl">
                        {headerTitle}
                    </span>
                    <div className={`rounded-full p-1 border border-indigo w-7 h-7 flex items-center justify-center ${isOpen && "bg-blue-500 text-white"}`}>
                        {/* icon by feathericons.com */}
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={isOpen ? "M5 15l7-7 7 7" : "M19 9l-7 7-7-7"} />
                        </svg>
                    </div>
                </header>
                {isOpen && <div>
                    <div className="pl-8 pr-8 pb-5 text-grey-darkest">
                        <p className="leading-relaxed text-gray-600 body-font">{children}</p>
                    </div>
                </div>}
            </div>
        </article>
    );
};



export { Accordion, AccordionItem };