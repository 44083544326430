import React from 'react';
import { StaticImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";

export default function Footer() {

    const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          socialMedia {
              instagram
              linkedin
          }
        }
      }
    }
  `);


    return (<footer className="text-gray-600 body-font shadow-md border-separate border-gray-700 border-t-1">
        <div className="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
            <div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
                <a className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900" href="#home">
                    <StaticImage
                        src="../../../static/atpclogo.png"
                        width={180}
                        height={80}
                        alt='A-Team Pro Cleaning Ltd'
                    />
                </a>
                <p className="mt-2  text-sm text-gray-500 pl-4">All your cleaning needs!</p>
            </div>
            <div className="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center">
                <div className="w-full px-4">
                    <div className="bg-white relative flex flex-wrap py-6 rounded shadow-md">
                        <div className="lg:w-1/3 px-6">
                            <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">ADDRESS</h2>
                            <p className="mt-1">3889 Chesswood Drive<br />
                        North York Toronto, <br />ON, M3J 2R8</p>
                        </div>
                        <div className="lg:w-1/3 px-6 mt-4 lg:mt-0">
                            <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">EMAIL</h2>
                            <a className="text-blue-500 leading-relaxed" href="#home">info@ateamprocleaning.ca</a>
                            <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">PHONE</h2>
                            <p className="leading-relaxed">647 444 7160</p>
                        </div>
                        <div className="lg:w-1/3 px-6 mt-4 lg:mt-0">
                            <span className="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
                                <a className="ml-3 text-gray-600 rounded-full p-1 hover:bg-gray-100 hover:text-gray-800" href={data.site.siteMetadata.socialMedia.instagram} target="_blank" rel="noreferrer">
                                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                                        <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                                        <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                                    </svg>
                                </a>
                                <a className="ml-3 text-gray-600 rounded-full p-1 hover:bg-gray-100 hover:text-gray-800" href={data.site.siteMetadata.socialMedia.linkedin} target="_blank" rel="noreferrer">
                                    <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0" className="w-5 h-5" viewBox="0 0 24 24">
                                        <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
                                        <circle cx="4" cy="4" r="2" stroke="none"></circle>
                                    </svg>
                                </a>
                            </span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div className="bg-gray-700 shadow-lg">
            <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
                <p className="text-white text-sm text-center sm:text-left">© {new Date().getFullYear()} A Team Pro Cleaning Ltd
                </p>
                <span className="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
                    <a className="ml-3 text-white rounded-full p-1 hover:bg-gray-200 hover:text-gray-800" href={data.site.siteMetadata.socialMedia.instagram} target="_blank" rel="noreferrer">
                        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                            <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                            <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                        </svg>
                    </a>
                    <a className="ml-3 text-white rounded-full p-1 hover:bg-gray-200 hover:text-gray-800" href={data.site.siteMetadata.socialMedia.linkedin} target="_blank" rel="noreferrer">
                        <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0" className="w-5 h-5" viewBox="0 0 24 24">
                            <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
                            <circle cx="4" cy="4" r="2" stroke="none"></circle>
                        </svg>
                    </a>
                </span>
            </div>
        </div>
    </footer>
    );
}