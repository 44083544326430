import React from 'react';
import { SECTION_CLASS } from '../../constants';

const backgroundColor = {
    gray: 'bg-gray-100',
    white: 'bg-white'
};


export default function SectionLayout({ sectionId, sectionTitle, subTitle, thirdSubTitle, children, bgColor, hasFullWidth, hasFullRow }) {

    const background = bgColor ? backgroundColor[bgColor] : backgroundColor.white;

    const divFullWidth = hasFullWidth === true || hasFullRow === true ? 'sm-w-full' : 'sm:w-3/4';
    const mainContainer = hasFullWidth ? '' : 'max-w-5xl';

    return (
        <section id={sectionId} className={`${background} ${SECTION_CLASS} py-24 px-6 min-h-screen w-full`}>
            <div className={`${mainContainer} container mx-auto m-8 min-h-full`}>
                {sectionTitle && <h1 className={`w-full my-2 text-4xl ${subTitle ? 'mb-5' : 'mb-20'} font-extrabold leading-tight text-center text-gray-600`}>{sectionTitle}</h1>}
                {subTitle && <h2 className={`w-full my-2 text-md ${thirdSubTitle ? 'mb-5' : 'mb-20'} font-extrabold leading-tight text-center text-gray-500 italic`}>{subTitle}</h2>}
                {thirdSubTitle && <h2 className="w-full my-2 text-md mb-20 font-extrabold leading-tight text-center text-gray-500 italic">{thirdSubTitle}</h2>}
                <div className="flex items-center flex-wrap mb-20 mt-6">
                    <div className={`${divFullWidth} w-full mx-auto`}>
                        {children}
                    </div>
                </div>
            </div>
        </section>
    );
}