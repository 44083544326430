import React from 'react';

function Step({ title, children }) {
    return (
        <div className="text-gray-600 body-font">
            <div className="container px-5 py-24 mx-auto flex flex-wrap">
                <h1 className="font-medium title-font text-xl text-gray-900 mb-1 tracking-wider text-center m-auto">{title}</h1>
                <div className="flex flex-wrap w-full">
                    <div className="w-full md:pr-10 md:py-6">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};


Step.Item = ({ header, children }) => {
    return (
        <div className="flex relative pb-12">
            <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div className="h-full w-1 bg-gray-200 pointer-events-none" />
            </div>
            <div className="flex-shrink-0 w-10 h-10 rounded-full bg-blue-500 inline-flex items-center justify-center text-white relative">
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                    className="w-5 h-5" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                </svg>
            </div>
            <div className="flex-grow pl-4">
                <h2 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">{header}</h2>
                <div className="leading-relaxed text-justify">{children}</div>
            </div>
        </div>
    );
};

Step.LastItem = ({ header, children }) => {

    return (
        <div className="flex relative">
            <div className="flex-shrink-0 w-10 h-10 rounded-full bg-blue-500 inline-flex items-center justify-center text-white relative">
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                    className="w-5 h-5" viewBox="0 0 24 24">
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
                    <path d="M22 4L12 14.01l-3-3" />
                </svg>
            </div>
            <div className="flex-grow pl-4">
                <h2 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">{header}</h2>
                <p className="leading-relaxed">{children}</p>
            </div>

        </div>
    );
};




export default Step;