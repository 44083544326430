import React from 'react';
function Card({ children }) {



    return (
        <div className="flex flex-wrap overflow-hidden sm:-mx-5 justify-center">
            {children}
        </div>
    );
}


Card.Item = ({ headerTitle, subHeaderTitle, imageHeader, children }) => {

    return (
        <div className="flex justify-center sm:w-1/2 w-full my-3 overflow-hidden">
            <div className={`w-11/12`}>
                <div className="overflow-hidden w-full shadow-md hover:shadow-xl transition-shadow duration-300 ease-in-out rounded-lg h-auto"
                    style={{ height: "98%" }}>
                    <div className="w-full">{imageHeader}</div>
                    <div className="px-6 py-4">
                        <div className="font-bold text-3xl mb-2 text-gray-800 text-center">{headerTitle}</div>
                        <div className="font-bold text-md italic text-center mb-2 text-gray-600 pt-2">{subHeaderTitle}</div>
                        <div className="text-gray-700 pt-2 text-base">{children}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default Card;