import React from 'react';

import { NAV_ITEM } from '../../../constants';

export function NavList(props) {
    const { navbarOpen, children } = props;
    return (
        <div
            className={
                "lg:flex" +
                (navbarOpen ? " flex" : " hidden")
            }
            id="example-navbar-danger"
        >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
                {children}
            </ul>
        </div>
    );
}

NavList.Item = (props) => {
    const { icon, children, scrollTo, sectionId } = props;


    return (
        <li className="nav-item">
            <button sectionid={sectionId} className={`px-3 py-2 font-mono flex items-center text-xs text-gray-500 font-bold outline-none focus:outline-none
                transition duration-250 ease-in-out leading-snug 
                hover:bg-gray-200 hover:shadow rounded-md hover:text-gray-600 hover:shadow-md hover:shadow-inner ${NAV_ITEM}`}
                onClick={scrollTo}
            >
                {icon ?
                    <>
                        <div className="inline-block">{icon}</div>
                        <div className="inline-block ml-2">{children}</div>
                    </> : children}
            </button>
        </li >
    )
};

export default NavList;