import React from 'react';
import Navbar from './navbar';
import Footer from './Footer';
import { ScrollToTop } from '../shared';

export default function PageLayout({ children }) {
    return (
        <div>
            <Navbar />
            {children}
            <Footer />
            <ScrollToTop />
        </div>
    );
};