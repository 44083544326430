import React from 'react';
import { StaticImage } from "gatsby-plugin-image";
import Card from '../shared/Card';



export default function Services() {

    return (
        <div className="w-full">
            <Card>
                <Card.Item
                    headerTitle="Cleaning and Maintenance"
                    subHeaderTitle="SUPERINTENDENT, HEAVY DUTY CLEANER, LIGHT DUTY CLEANER, WE HAVE GOT IT ALL COVERED FOR YOU!"
                    imageHeader={<StaticImage src='../../../static/cleaningandmaintenance.jpg' className="w-full h-60" alt='Cleaning and Maintenance Toronto' />}
                >
                    Cleaning entrances, lobbies, common areas, staircases, and elevators. Cleaning and refreshing washrooms.
                    Touch point cleaning in areas where dirt and infection transfer risks are highest. Hard floor cleaning and maintenance.
                    Behind-the-scenes area cleaning (including offices, work areas, break rooms and storage areas).
                    Cleaning and maintenance of all shared facilities such as fitness room, swimming pools, locker rooms,
                    office space, party rooms, corridors, management office, garbage compactor rooms, and much more...
                </Card.Item>
                <Card.Item
                    headerTitle="Carpet Cleaning"
                    subHeaderTitle="TORONTO CARPET CLEANING AT ITS FINEST!"
                    imageHeader={<StaticImage src='../../../static/cleaningcarpet.png' className="w-full h-60" alt='Carpet cleaning Toronto' />}
                >
                    Greater Toronto Area. Our constantly expanding services now include carpet cleaning, upholstery cleaning,
                    allergy relief cleaning, pet stain, and odor removal, All our services include a 30-day guarantee.
                    We'll gladly return to re-clean any spots or stains should they re-appear within 30 days.
                    We want you to let us know right away if there are any concerns. We work hard to satisfy
                    each and every customer and want all our customers to be happy with our services.
                    We are sincerely committed to your total satisfaction.
                </Card.Item>
                <Card.Item
                    headerTitle="Window Cleaning"
                    subHeaderTitle="BRINGING YOU THE BEST IN HIGH RISE/TOWNHOUSES EXTERIOR WINDOW CLEANING!"
                    imageHeader={<StaticImage src='../../../static/window_cleaning.jpeg' className="w-full h-60" alt='Window Cleaning Toronto' />}
                >
                    When it comes to professional window and eaves cleaning services in the GTA, GC Window Cleaning Ltd,
                    a division of A-Team Pro Cleaning Ltd is your best choice. At our company we concentrate on developing,
                    efficient execution and the skills of our employees, in order to provide you with the best possible results.
                    We strongly believe that attention to small details and clients’ needs comes first. Working discreetly
                    and attentively, we do everything possible to make your life easy.
                </Card.Item>
                <Card.Item
                    headerTitle="Garage Pressure Wash"
                    subHeaderTitle="SCHEDULED AT YOUR NEEDS!"
                    imageHeader={<StaticImage src='../../../static/power_wash.png' className="w-full h-60" alt='Garage pressure wash Toronto' />}
                >
                    <ul className="list-disc p-5">
                        <li>Multiple Level Ramp Charge</li>
                        <li>Clean all pipes, walls & Pillars of dust and debris</li>
                        <li>Area drain jet flush</li>
                        <li>Trench Drain</li>
                        <li>Sump Pits</li>
                        <li>Catch basins</li>
                        <li>Exterior Sand traps</li>
                        <li>Exterior catch basins</li>
                    </ul>
                </Card.Item>
            </Card>


        </div>
    );
}