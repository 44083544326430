import React from 'react';


export default function ContactuS() {


    return (

        <div className="mx-auto flex sm:flex-nowrap flex-wrap">
            <div className="lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 sm:h-auto h-96 p-10 flex items-end justify-start relative shadow-md">
                <iframe width="100%" height="100%" className="absolute inset-0" frameBorder={0} title="map" marginHeight={0} marginWidth={0} scrolling="no" loading="lazy"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2881.727132161793!2d-79.47677488425377!3d43.757762853680376!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b31feaebe54f7%3A0xb6c241e7e9bd4a8!2s3889%20Chesswood%20Dr%2C%20North%20York%2C%20ON%20M3J%202R8%2C%20Canada!5e0!3m2!1sen!2sde!4v1600118914628!5m2!1sen!2sde"
                />
                <div className="bg-white relative flex flex-wrap py-6 rounded shadow-md">
                    <div className="lg:w-1/2 px-6">
                        <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">ADDRESS</h2>
                        <p className="mt-1">3889 Chesswood Drive<br />
                        North York Toronto, <br />ON, M3J 2R8</p>
                    </div>
                    <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
                        <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">EMAIL</h2>
                        <a className="text-blue-500 leading-relaxed" href="#home">info@ateamprocleaning.ca</a>
                        <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">PHONE</h2>
                        <p className="leading-relaxed">647 444 7160</p>
                    </div>
                </div>
            </div>

            <div className="lg:w-1/3 md:w-1/2 bg-white rounded-lg p-8 shadow-md flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
                <form
                    method="POST"
                    action="/sendemail/"
                    className="w-full">
                    <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">Feedback</h2>
                    <p className="leading-relaxed mb-5 text-gray-600">Contact us by sending an email!</p>
                    <div className="relative mb-4">
                        <label htmlFor="name" className="leading-7 text-sm text-gray-600">Name and Surname</label>
                        <input type="text" id="name" name="name" className="w-full bg-white rounded border border-gray-300 
                        focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 
                        px-3 leading-8 transition-colors duration-200 ease-in-out" required />
                    </div>
                    <div className="relative mb-4">
                        <label htmlFor="email" className="leading-7 text-sm text-gray-600">Email</label>
                        <input type="email" id="email" name="email" className="w-full bg-white rounded border border-gray-300 
                        focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 
                        leading-8 transition-colors duration-200 ease-in-out" required />
                    </div>
                    <div className="relative mb-4">
                        <label htmlFor="subject" className="leading-7 text-sm text-gray-600">Subject</label>
                        <input type="text" id="subject" name="subject" className="w-full bg-white rounded border border-gray-300 
                        focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 
                        leading-8 transition-colors duration-200 ease-in-out" required />
                    </div>
                    <div className="relative mb-4">
                        <label htmlFor="message" className="leading-7 text-sm text-gray-600">Message</label>
                        <textarea id="message" name="message" className="w-full bg-white rounded border border-gray-300 
                        focus:border-blue-500 focus:ring-2 focus:ring-blue-200 h-32 text-base outline-none text-gray-700 
                        py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out" defaultValue={""} required />
                    </div>
                    <button type="submit" className="text-white w-full bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-blue-600 rounded text-lg">Send Message!</button>
                    <p className="text-xs text-gray-500 mt-3">We'll be responding you as quick as possible!</p>
                </form>
            </div>

        </div>
    );

}
