import React from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import PageLayout from '../components/page-layout';

import {
  SectionLayout,
  HeroSection,
  ContactuS,
  AboutUs,
  Services,
  WindowCleaning,
  OurWork
} from '../components/sections';

import {
  HERO_SECTION_ID,
  WINDOW_CLEANING_ID,
  ABOUT_US_ID,
  SERVICES_ID,
  OUR_WORK_ID,
  CONTACT_US_ID,
} from '../constants';

export default function Home() {

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
          keywords
        }
      }
    }
  `);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.site.siteMetadata.title}</title>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="description" content={data.site.siteMetadata.description} />
        <meta name="author" content={data.site.siteMetadata.author} />
        <meta name="keywords" content={data.site.siteMetadata.keywords} />
        <link rel="canonical" href="https://atpc.ca" />
      </Helmet>
      <PageLayout>
        <HeroSection sectionId={HERO_SECTION_ID} />
        <SectionLayout
          hasFullRow
          isHeader
          sectionId={WINDOW_CLEANING_ID}
          sectionTitle='GC WINDOW CLEANING LTD'
          subTitle='(A DIVISION OF A-TEAM PRO CLEANING LTD, LICENSED TO PERFORM ONLY EXTERIOR WINDOW CLEANING)'
          thirdSubTitle="WINDOW CLEANING & POST-CONSTRUCTION WINDOW CLEANING"
          bgColor='gray'>
          <WindowCleaning />
        </SectionLayout>
        <SectionLayout
          sectionId={ABOUT_US_ID}
          sectionTitle='About Us'>
          <AboutUs />
        </SectionLayout>
        <SectionLayout
          hasFullRow
          sectionId={SERVICES_ID}
          sectionTitle='Services'
          bgColor='gray'>
          <Services />
        </SectionLayout>
        <SectionLayout
          hasFullRow
          sectionId={OUR_WORK_ID}
          sectionTitle='Our Work'>
          <OurWork />
        </SectionLayout>
        <SectionLayout
          sectionId={CONTACT_US_ID}
          sectionTitle='Contact Us'
          bgColor='gray'
          hasFullWidth
        >
          <ContactuS />
        </SectionLayout>
      </PageLayout>
    </>
  );
}
