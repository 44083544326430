import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import NavList from './NavList';
import scrollTo from 'gatsby-plugin-smoothscroll';

import { OfficeBuilding, Info, Cog, Camera, Mail } from '../../../assets/icons';

import {
    HERO_SECTION_ID,
    WINDOW_CLEANING_ID,
    ABOUT_US_ID,
    SERVICES_ID,
    OUR_WORK_ID,
    CONTACT_US_ID,
    NAV_ITEM,
} from '../../../constants';


export default function Navbar() {
    const [navbarOpen, setNavbarOpen] = React.useState(false);

    const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          socialMedia {
              instagram
              linkedin
          }
        }
      }
    }
  `);


    React.useEffect(() => {
        let mainNavLinks = document.querySelectorAll(`.${NAV_ITEM}`);

        window.addEventListener('scroll', event => {
            let fromTop = window.scrollY - 50;

            mainNavLinks.forEach(link => {
                let section = document.querySelector(`#${link.getAttribute('sectionid')}`);
                if (
                    section.offsetTop <= fromTop + 55 &&
                    section.offsetTop + section.offsetHeight > fromTop + 55
                ) {
                    link.classList.add("bg-gray-200");
                } else {
                    link.classList.remove("bg-gray-200");
                }
            });
        });


    }, []);



    return (
        <nav className="fixed flex flex-wrap items-center justify-between px-2
            navbar-expand-lg bg-white w-screen h-24 mb-24 top-0 shadow-md z-10 border-b-2">
            <div className="container bg-white px-4 mx-auto flex flex-wrap items-center justify-between">
                <div className="flex flex-grow">
                    <div>
                        <StaticImage
                            src="../../../../static/atpclogo.png"
                            width={180}
                            height={80}
                            alt='A-Team Pro Cleaning Ltd'
                        />
                    </div>
                    <div className="flex items-center justify-center md:flex-grow">
                        <a className="ml-3 text-gray-600 h-7 rounded-full p-1 hover:bg-gray-300 hover:text-gray-800" href={data.site.siteMetadata.socialMedia.instagram} target="_blank" rel="noreferrer">
                            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                                <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                                <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                            </svg>
                        </a>
                        <a className="ml-3 text-gray-600 h-7 rounded-full p-1  hover:bg-gray-300 hover:text-gray-800" href={data.site.siteMetadata.socialMedia.linkedin} target="_blank" rel="noreferrer">
                            <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0" className="w-5 h-5" viewBox="0 0 24 24">
                                <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
                                <circle cx="4" cy="4" r="2" stroke="none"></circle>
                            </svg>
                        </a>
                    </div>
                    <div className="flex items-center">
                        <button
                            className="text-white cursor-pointer text-xl leading-none pl-8 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                            type="button"
                            onClick={() => setNavbarOpen(!navbarOpen)}
                        >
                            <span className="block relative w-6 h-px rounded-sm bg-gray-500"></span>
                            <span className="block relative w-6 h-px rounded-sm bg-gray-500 mt-1"></span>
                            <span className="block relative w-6 h-px rounded-sm bg-gray-500 mt-1"></span>
                        </button>
                    </div>

                </div>

                <NavList navbarOpen={navbarOpen}>
                    <NavList.Item sectionId={WINDOW_CLEANING_ID} scrollTo={() => {
                        scrollTo(`#${WINDOW_CLEANING_ID}`);
                        setNavbarOpen(false);
                    }}
                        icon={<OfficeBuilding />}>Window Cleaning</NavList.Item>
                    <NavList.Item sectionId={ABOUT_US_ID} scrollTo={() => {
                        scrollTo(`#${ABOUT_US_ID}`);
                        setNavbarOpen(false);
                    }}
                        icon={<Info />}>About Us</NavList.Item>
                    <NavList.Item sectionId={SERVICES_ID} scrollTo={() => {
                        scrollTo(`#${SERVICES_ID}`);
                        setNavbarOpen(false);
                    }} icon={<Cog />}>Services</NavList.Item>
                    <NavList.Item sectionId={OUR_WORK_ID} scrollTo={() => {
                        scrollTo(`#${OUR_WORK_ID}`);
                        setNavbarOpen(false);
                    }} icon={<Camera />}>Our Work</NavList.Item>
                    <NavList.Item sectionId={CONTACT_US_ID} scrollTo={() => {
                        scrollTo(`#${CONTACT_US_ID}`);
                        setNavbarOpen(false);
                    }} icon={<Mail />}>Contact Us</NavList.Item>
                </NavList>

            </div>
        </nav >

    );
}