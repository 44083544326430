import React from 'react';

import { Testimonial } from '../shared';

export default function OurWork() {


    return (
        <>
            <div className="flex flex-wrap overflow-hidden sm:-mx-5 justify-center">
                <div className="flex justify-center sm:w-1/2 w-full my-3 overflow-hidden" style={{ height: "23rem" }}>
                    <iframe width="95%" title="Elevated machine boom,Juliet window + canopy cleaning"
                        height="315" src="https://www.youtube.com/embed/Sixq5A_0CJo"
                        frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen="" loading="lazy"></iframe>

                </div>
                <div className="flex justify-center sm:w-1/2 w-full my-3 overflow-hidden" style={{ height: "23rem" }}>
                    <iframe width="95%" title="A team Pro Cleaning Ltd" height="315" src="https://www.youtube.com/embed/MaSdEUPPBKs"
                        frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen="" loading="lazy"></iframe>

                </div>
                <div className="flex justify-center sm:w-1/2 w-full my-3 overflow-hidden" style={{ height: "23rem" }}>
                    <iframe width="95%" title="A Team Pro Cleaning Ltd Greater Toronto Area" height="315" src="https://www.youtube.com/embed/_DAXunW0ylQ"
                        frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen="" loading="lazy"></iframe>

                </div>
                <div className="flex justify-center sm:w-1/2 w-full my-3 overflow-hidden" style={{ height: "23rem" }}>
                    <iframe width="95%" title="A team Pro Cleaning Ltd Toronto" height="315" src="https://www.youtube.com/embed/emiuNuSArlk"
                        frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen="" loading="lazy"></iframe>
                </div>
            </div>
            <Testimonial mainTitle="Testimonials">
                <Testimonial.Item nameOfReviewer="Judy">
                    It was gratifying to refer this company to my customer. Their attention to detail and outstanding results was beyond satisfactory.
                </Testimonial.Item>
                <Testimonial.Item nameOfReviewer="Benjamin">
                    I just wanted to say how much I appreciate the excellent quality of work done by your staff. it isn't often that you can have a service provider perform the tasks you hired them for and have the job done well. I highly recommend ATPC as it is just what you need
                </Testimonial.Item>
                <Testimonial.Item nameOfReviewer="Paul">
                    Friendly. Professional. And did a thorough job. Look forward to future businesd!
                </Testimonial.Item>
            </Testimonial>
        </>
    );
}