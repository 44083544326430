import React from 'react';
import Flickity from 'react-flickity-component';
import scrollTo from 'gatsby-plugin-smoothscroll';

import "flickity/css/flickity.css";
import "./hero-section.css";

import janitorial from '../../../../static/janitorial.jpg';
import windowCleaning from '../../../../static/window_cleaning.jpeg';

import { WINDOW_CLEANING_ID, SERVICES_ID } from '../../../constants';




const flickityOptions = {
    accessibility: true,
    prevNextButtons: true,
    autoPlay: true,
    lazyLoad: true,
    pageDots: true,
    setGallerySize: false,
    arrowShape: {
        x0: 10,
        x1: 60,
        y1: 50,
        x2: 60,
        y2: 45,
        x3: 15
    }
};

export default function HeroSection({ sectionId }) {



    return (
        <section id={sectionId} className={`border-b pt-24 min-h-screen w-full`}>
            <Flickity options={flickityOptions} className={"heroSlider"}>
                <div className="carouselCell" style={{ backgroundImage: `url(${janitorial})` }}>
                    <div className="overlay"></div>
                    <div className="inner">
                        <h3 className="subtitle" style={{ textShadow: '0px 0px 3px #000, -1px -1px #000, 1px 1px #000' }}>Safe, Attractive and Healthier Environment</h3>
                        <h2 className="title" style={{ textShadow: '0px 0px 3px #000, -1px -1px #000, 1px 1px #000' }}>Janitorial Services</h2>
                        <button onClick={() => scrollTo(`#${SERVICES_ID}`)} className="btn">Tell me more</button>
                    </div>
                </div>
                <div className="carouselCell" style={{ backgroundImage: `url(${windowCleaning})` }}>
                    <div className="overlay"></div>
                    <div className="inner">
                        <h3 className="subtitle" style={{ textShadow: '0px 0px 3px #000, -1px -1px #000, 1px 1px #000' }}>Your Standards Are Our Standards!</h3>
                        <h2 className="title" style={{ textShadow: '0px 0px 3px #000, -1px -1px #000, 1px 1px #000' }}>Window Cleaning</h2>
                        <button onClick={() => scrollTo(`#${WINDOW_CLEANING_ID}`)} className="btn">Tell me more</button>
                    </div>
                </div>


            </Flickity>
        </section>
    );
}